<template>
  <div class="ui-info-item_title">{{ title }}</div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>
